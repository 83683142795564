// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
window.jQuery = $;
window.$ = $;

require("bootstrap");
require("pace-js");

Pace.start();

// Remove this demo code, that's only here to show how the .env file works!
if (process.env["HELLO"]) {
  console.log(`Hello ${process.env.HELLO}!`);
}


$(document).ready(function() {
  $("#loader").delay(300).slideUp();
  $('[data-toggle="tooltip"]').tooltip()

  $('.navbar-nav>li>a').on('click', function(){
    $('.navbar-collapse').collapse('hide');
  });

  $(window).scroll(function() {
    var y = $(window).scrollTop();
    if (y > 150) {
      $("#navbar").addClass("shadow-sm");
    } else {
      $("#navbar").removeClass("shadow-sm");
    }
  });

  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate({ scrollTop: target.offset().top - 30 }, 1000);
        }
      }
    });

  var portfolioSlides = new Swiper('.portfolio-slides-container', {
    // Optional parameters
    loop: true,
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  var workProcessSlides = new Swiper('#work-process-mobile', {
    // Optional parameters
    loop: true,
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  var clientsSlides = new Swiper('.clients-slides-container', {
    // Optional parameters
    loop: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      500: {
        slidesPerView: 3,
      },
      400: {
        slidesPerView: 1,
      },
      300: {
        slidesPerView: 1,
      },
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  $(document).scroll(function () {
    var $nav = $("#navbar");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
});
